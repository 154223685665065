import React, { Component } from "react";
import {
    Container, Row, Col, Input, Button, FormGroup, FormFeedback, PaginationItem,
    PaginationLink
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CompanyAccept from "./CompanyAccept";
import SearchOrder from "./SearchOrder";
import SelectDriver from "./SelectDriver";
import RequestInfo from "./RequestInfo";
import { Redirect } from "react-router";
import { Post, Get } from "../../../utilities/axiosRequest";
import i18next from 'i18next';
import Pagination from "react-js-pagination";
import axios from 'axios';
import { getDistance } from 'geolib';

const validationSchema = Yup.object().shape({
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    deviceModel: Yup.string()
        .min(1, "Enter valid Device Model")
        .required("Device model is required")
});


class ListMaintanances extends Component {

    constructor(props) {
        super(props);
        console.log("Props...", props)
        //this.handleClick = this.handleClick.bind(this);
    }

    state = {
        mobileNumber: "",
        selectedOrder: null,
        deviceModel: "",
        isSearch: false,
        isShow: "",
        requests: [],
        collectstatus: [
            {
                value: 0,
                label: "Nothing"
            },
            {
                value: 12,
                label: "السائق استلم"
            },
            {
                value: 13,
                label: "أعيد للعميل"
            }
        ],


        showModal: false,
        assignTo: '',
        showlocation: false,
        spinnerLoading: false,
        show: false,
        message: '',
        activePage: 1,
        page: 1,
        itemsCountPerPag: 1,
        totalItemsCount: 1,
        error: '',
        startLat: 21.419,
        startLong: 39.858,
        distance: '',
        store_id: '',
        newpendingonly: true,
        total_company_summary: []


    }


    setShow = (status) => {
        this.setState({ show: status });

    }

    static getDerivedStateFromProps(props, state) {

        return {
            requests: state.requests,
            isSearch: true,
        }



    }
    displayTime = (dispTime) => {
        console.log("displ", dispTime)
        return dispTime;


    }

    infoLocation = (selectedOrder) => {
        this.setState({ showlocation: true });
        console.log("Selected ordfder", selectedOrder)
    }


    askDriver = (request_id, config_val) => {
        Post(`https://api.ghdear.com/api/v1/driver/add-driver-request`, { request_id: request_id, config_val: config_val }).then(result => {
            this.setState({ message: i18next.language == "en" ? 'Request given to driver' : 'تم إرسال الطلب للسائق' });
            this.setState({ show: true })

        })
    }

    trackMobile = (order, type) => {

        order.contact_type = type;
        Post(`https://api.ghdear.com/api/v1/store/update-tracking-mobile`, order).then(result => {
            if (type == 1) {
                window.open("tel:" + order.customer_mob, "_blank");

            } else {

                window.open("https://wa.me/" + order.customer_mob, "_blank");


            }

        }

        );

    }


    modalTogglerHandler = () => {
        this.setState({ showlocation: false });
    }
    updateLoctaionCord = (lat, lng) => {
        this.setState({ lat: lat, lng: lng });


    }
    togglePop = () => {

        this.setState({
            showModal: !this.state.showModal
        });
    };

    componentDidUpdate(prevProps, prevState) {

        console.log("props...", prevProps.statusid)

        console.log("props...", this.props.statusid)
        if (prevProps.statusid !== this.props.statusid) {
            Get(`https://api.ghdear.com/api/v1/store/all-pending-requests?&statusid=${this.props.statusid}`).then(result => {

                if (result.error == "invalid") {
                    //this.props.history.push("/login");
                    this.setState({ error: "invalid" })

                } else {

                    this.setState({ requests: result.requests.data, country_id: result.country_id, city_id: result.city_id, totalItemsCount: result.total, isShow: '', store_id: result.store_id });
                }


            })
        }
    }
    getItems() {

        Get("https://api.ghdear.com/api/v1/user/send-requests-to-stores").then(result => console.log("rsponse...", result));

    }
    componentDidMount(props) {
        const notincludestatus = [12, 13];
        const status = [];

        if (this.props.statusid != undefined) {
            Get(`https://api.ghdear.com/api/v1/store/all-pending-requests?&statusid=${this.props.statusid}`).then(result => {

                if (result.error == "invalid") {
                    //props.history.push("/login");
                    this.setState({ error: "invalid" })

                } else {

                    this.setState({ requests: result.requests.data, country_id: result.country_id, city_id: result.city_id, totalItemsCount: result.total, store_id: result.store_id, newpendingonly: false });
                }


            })
        } else {
            Get(`https://api.ghdear.com/api/v1/store/pending-new-requests`).then(result => {

                if (result.error == "invalid") {
                    // props.history.push("/login");
                    this.setState({ error: "invalid" })

                } else {
                    console.log("Requets...", result.requests.data);
                    this.setState({ requests: result.requests.data, country_id: result.country_id, city_id: result.city_id, totalItemsCount: result.total, store_id: result.store_id, newpendingonly: true });
                }


            })

        }

        Get("https://api.ghdear.com/api/v1/store/fixing-status").then(result => {
            result.fixingstatus.forEach(function (data, idx) {
                if (notincludestatus.indexOf(data.req_status_id) > -1) { } else { status.push({ 'value': data.req_status_id, 'label': i18next.language == "en" ? data.title : data.title_ar }); }
            });

            this.setState({ fixingstatus: status });
        });


        var dis = getDistance(
            { latitude: 52.528308, longitude: 1.3817765 },
            { latitude: 31.6200, longitude: 74.8765 },
        );
        console.log("distance in km", dis / 1000);

        Get("https://api.ghdear.com/api/v1/store/report-summary").then(result => {
            this.setState({ reportsummary: result.reportsummary, total_company_summary: result.total_company_summary });
        });



    }

    getCurrentLocationCordinates = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                // console.log(position)
                this.setState({
                    startLat: position.coords.latitude,
                    startLong: position.coords.longitude
                });

            });

        }
    }

    orderInformationHandler = async (selectedOrder) => {
        console.log("Selected order..accept", selectedOrder);


        if (selectedOrder.loc_lattitude != null && selectedOrder.loc_longittude != null) {
            var dis = getDistance(
                { latitude: this.state.startLat, longitude: this.state.startLong },
                { latitude: selectedOrder.loc_lattitude, longitude: selectedOrder.loc_longittude },
            );
            this.setState({
                distance: dis / 1000,
            });
        } else {

            this.setState({
                distance: 'not specified',
            });
        }

        console.log("distance in km", this.state.distance);
        this.props.handleDashCountSummary(false);
        if (selectedOrder.req_status_id == 1) {
            this.setState({ selectedOrder: selectedOrder, isShow: "RequestInfo" });

        } else {

            this.setState({ selectedOrder: selectedOrder, isShow: "CompanyAccept" });
        }

    }
    selectDriverHandler = (selectedDriver) => {

        this.setState({ selectedDriver: selectedDriver });
    }

    assignRequestTo = (assignto) => {

        this.setState({ assignTo: assignto });
    }

    changeComponent = (componentName) => {
        this.setState({ isShow: componentName });
        this.props.handleDashCountSummary(false);
    }

    saveRequestDetails = (values) => {
        console.log("Values....", values);
        //console.log("selected order....",selectedOrder);
        const dataArr = {
            fixingstatus: values.fixingstatus,
            collectstatus: values.collectstatus,
            request_id: values.request_id,
            explain_fix: values.explain_fix,
            visit_amount: values.visit_amount,
            service_amount: values.service_amount,
            explanation_visit: values.explanation_visit,
            explanation_service: values.explanation_service,


        };
        this.setState({ spinnerLoading: true })
        Post(`https://api.ghdear.com/api/v1/store/update-fixing-request`, dataArr).then(result => {

            if (result.result == "success") {
                this.setState({ message: i18next.language == "en" ? 'Saved Successfully' : "تم الحفظ" })
                this.setState({ show: true })
            } else {
                this.setState({ message: i18next.language == "en" ? 'You dont have permission to change visit cost' : "You dont have permission to change visit cost" })
                this.setState({ show: true })
            }

            //window.location.reload(true);


        }

        );
    }

    handlePageChange(pageNumber) {
        console.log("Page Number", pageNumber);
        console.log("statusid..", this.props.statusid);
        this.setState({ activePage: pageNumber });
        this.setState({ page: pageNumber });
        if (this.props.statusid != undefined) {
            const status_id = this.props.statusid;
        } else {
            const status_id = 0;

        }
        if (this.state.newpendingonly == true) {
            axios.get(`https://api.ghdear.com/api/v1/store/pending-new-requests`, {
                params: this.state
            }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {

                this.setState({ requests: resp.data.requests.data });

            })

        } else {
            axios.get(`https://api.ghdear.com/api/v1/store/all-pending-requests?&statusid=${this.props.statusid}`, {
                params: this.state
            }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
                console.log("Response pagination", resp.data.requests.data)
                this.setState({ requests: resp.data.requests.data });

            })

        }





    }

    changeOrderStatus = (status) => {


        const dataArr = {

            status: status,
            request_id: this.state.selectedOrder.request_id,
            lat: this.state.selectedOrder.loc_lattitude,
            lng: this.state.selectedOrder.loc_longittude,
            customer_id: this.state.selectedOrder.customer_id,

            service_id: this.state.selectedOrder.service_id,
            task_id: this.state.selectedOrder.task_id


        }
        this.setState({ spinnerLoading: true })

        Post(`https://api.ghdear.com/api/v1/store/accept-request`, dataArr).then(result => {
            if (result.result == 'success') {
                if (result.status == 1) {
                    this.setState({ message: i18next.language == "en" ? 'Request is accepted' : "نأمل الإتصال بالعميل فورا" })
                    const interval = setTimeout(() => {
                        this.setState({ isShow: "", message: "", show: false });
                    }, 3000);

                    // this.setState({isShow :""});
                } else {
                    this.setState({ message: i18next.language == "en" ? 'Request is Denied' : "لقد تم رفض طلب العميل من قبلكم" })

                }
            } else if (result.result == 'taken') {
                this.setState({ message: i18next.language == "en" ? 'Request is already taken' : "هذه الخدمة اسندت إلى شركة أخرى" })

            }

            this.setState({ show: true });


            // window.location.reload();

        }
        );

    }

    denyRequest = (status) => {

        this.setState({ spinnerLoading: true })
        const dataArr = {
            request_id: this.state.selectedOrder.request_id,

        }
        Post(`https://api.ghdear.com/api/v1/store/deny-request`, dataArr).then(result => {
            this.setState({ message: result.message });
            this.setState({ show: true })
            window.location.reload(true);
        }
        );


    };


    submitRequestAssign = () => {


        const dataArr = {


            request_id: this.state.selectedOrder.request_id,
            assign_To: this.state.assignTo


        }


        Post(`https://api.ghdear.com/api/v1/store/assign-fixing-request`, dataArr).then(result => console.log("result....", result));

    }


    checkBoxChangeHandler = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    }

    render() {

        const { requests, isSearch, selectedOrder, isShow, fixingstatus, collectstatus, showModal, assignTo, showlocation, spinnerLoading, show, message, activePage, page, itemsCountPerPag, totalItemsCount, error, distance, total_company_summary } = this.state;

        if (error != "") return <Redirect to="/login" from="*" />
        if (localStorage.getItem("token") == "") return <Redirect to="/home" from="*" />

        console.log("requests..", requests)

        return (
            <>
                {isShow === "CompanyAccept" ?
                    <CompanyAccept selectedOrder={selectedOrder} updateLoctaionCord={this.updateLoctaionCord} spinnerLoading={spinnerLoading} show={show} changeOrderStatus={this.changeOrderStatus} message={message} setShow={this.setShow} distance={distance} />

                    : <>
                        {isShow === "RequestInfo" ?
                            <RequestInfo changeComponent={this.changeComponent} fixingstatus={fixingstatus} collectstatus={collectstatus} saveRequestDetails={this.saveRequestDetails} selectedOrder={selectedOrder} checkBoxChangeHandler={this.checkBoxChangeHandler} showModal={showModal} togglePop={this.togglePop} assignRequestTo={this.assignRequestTo} assignTo={assignTo} showlocation={showlocation} modalTogglerHandler={this.modalTogglerHandler} infoLocation={this.infoLocation} updateLoctaionCord={this.updateLoctaionCord} show={show} message={message} setShow={this.setShow} denyRequest={this.denyRequest} trackMobile={this.trackMobile} askDriver={this.askDriver} />

                            : <>

                                {isShow === "selectDriver" ?
                                    <SelectDriver changeComponent={this.changeComponent} selectedOrder={selectedOrder} />

                                    : <>
                                        <Row className="px-1 mt-1">
                                            {
                                                isSearch ? requests.length ?
                                                    <SearchOrder requests={requests} total_company_summary={total_company_summary} displayTime={this.displayTime} trackMobile={this.trackMobile} handleClick={this.orderInformationHandler} activePage={activePage} page={page} itemsCountPerPag={itemsCountPerPag} totalItemsCount={totalItemsCount} handlePageChange={this.handlePageChange.bind(this)} />
                                                    : <Col><h5 className="text-center">Order Not Found</h5></Col> : null
                                            }
                                        </Row>
                                    </>
                                }
                            </>
                        }
                    </>


                }

            </>
        );
    }
};

export default ListMaintanances;